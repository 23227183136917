import Freshlink from "../Freshlink.png";
import {
  Avatar,
  Box,
  Button,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  ToastId,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import useUser from "../lib/useUser";
import { logOut } from "../api";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useRef } from "react";

export default function Header() {
  const { userLoading, isLoggedIn, user } = useUser();

  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();
  const {
    isOpen: isSignUpOpen,
    onClose: onSignUpClose,
    onOpen: onSignUpOpen,
  } = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();

  const mutation = useMutation({
    mutationFn: logOut,
    onMutate: () => {
      toastId.current = toast({
        title: "로그아웃",
        description: "처리중...",
        status: "loading",
        position: "bottom-right",
      });
    },
    onSuccess: () => {
      if (toastId.current) {
        queryClient.refetchQueries({
          queryKey: ["me"],
        });
        toast.update(toastId.current, {
          status: "success",
          title: "정상 처리 되었습니다.",
          position: "bottom-right",
        });
      }
    },
  });

  const onlogOut = async () => {
    mutation.mutate();
  };

  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      py={5}
      px={40}
      direction={{
        base: "column",
        sm: "column",
        md: "row",
      }}
      spacing={{
        sm: 3,
        md: 0,
      }}
      borderBottomWidth={1}
    >
      <Box color="green.500" mb={{ base: 3, sm: 3, md: 0 }}>
        <Link to={"/"}>
          <Image src={Freshlink} w={100}></Image>
        </Link>
      </Box>

      <HStack spacing={2}>
        {!userLoading ? (
          !isLoggedIn ? (
            <>
              <Button onClick={onLoginOpen}>로그인</Button>
              <Button onClick={onSignUpOpen} colorScheme={"green"}>
                가입하기
              </Button>
            </>
          ) : (
            <>
              <Menu>
                <MenuButton>
                  <Avatar name={user?.username} size={"md"}></Avatar>
                </MenuButton>
                <MenuList>
                  {user?.is_producer || user?.is_distributor ? (
                    user?.is_producer && !user?.is_distributor ? (
                      <>
                        <MenuItem>
                          <Link to={"/product/upload"}>생산품 등록하기</Link>
                        </MenuItem>
                      </>
                    ) : (
                      <>
                        <MenuItem>생산자 찾기</MenuItem>
                      </>
                    )
                  ) : (
                    <>
                      <MenuItem>
                        <Link to={"/user/producer"}>생산자 등록하기</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link to={"/user/distributor"}>유통업체 등록하기</Link>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem onClick={onlogOut}>로그아웃</MenuItem>
                </MenuList>
              </Menu>
            </>
          )
        ) : null}
      </HStack>
      <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
      <SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} />
    </Stack>
  );
}
