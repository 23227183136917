import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import NotFound from "./routes/NotFound";
import ProductDetail from "./routes/ProductDetail";
import UploadProduct from "./routes/UploadProduct";
import UploadImeges from "./routes/UploadImages";
import SendOffer from "./routes/sendOffer";
import ProductsList from "./components/ProductsList";
import VarietyList from "./components/VarietyList";
import CreateProducer from "./routes/CreateProducer";
import CreateDistributor from "./routes/CreateDistributor";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "product",
        element: <ProductsList />,
      },
      {
        path: "variety",
        element: <VarietyList />,
      },
      {
        path: "product/upload",
        element: <UploadProduct />,
      },
      {
        path: "product/:productId",
        element: <ProductDetail />,
      },
      {
        path: "product/:productId/images",
        element: <UploadImeges />,
      },
      {
        path: "product/:productId/offer",
        element: <SendOffer />,
      },
      {
        path: "user/producer",
        element: <CreateProducer />,
      },
      {
        path: "user/distributor",
        element: <CreateDistributor />,
      },
    ],
  },
]);
export default router;
