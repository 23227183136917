import Cookies from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : "https://backend.freshlink.kr/api/v1/",
  withCredentials: true,
});

export const getProducts = () =>
  instance.get("products/product/").then((response) => response.data);

export const getProduct = ({ queryKey }: QueryFunctionContext) => {
  const [_, productId] = queryKey;
  return instance
    .get(`products/product/${productId}`)
    .then((response) => response.data);
};

export const getProductOffers = ({ queryKey }: QueryFunctionContext) => {
  const [_, productId] = queryKey;
  return instance
    .get(`products/product/${productId}/offers`)
    .then((response) => response.data);
};

export const getMe = () =>
  instance.get(`users/user/me/`).then((response) => response.data);

export const logOut = () =>
  instance
    .post(`users/user/logout/`, null, {
      data: {},
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface ILoginVariables {
  username: string;
  password: string;
}

export interface ILoginSuccess {
  ok: string;
}
export interface ILoginError {
  error: string;
}

export const usernameLogin = ({ username, password }: ILoginVariables) =>
  instance.post(
    `users/user/login/`,
    { username, password },
    {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
      },
    }
  );

// sign up
export interface ISignupVariables {
  username: string;
  password: string;
  email: string;
  name: string;
}

export const signUp = (variables: ISignupVariables) =>
  instance
    .post(`users/user/`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getDifferences = () =>
  instance.get(`products/differences/`).then((response) => response.data);

export const getKind = () =>
  instance.get(`products/kind/`).then((response) => response.data);

export const getVarieties = () =>
  instance.get(`products/variety/`).then((response) => response.data);

// export const getKindVarieties = ({ queryKey }: QueryFunctionContext) => {
//   const [_, kindId] = queryKey;
//   return instance
//     .get(`products/kind/${kindId}/variety/`)
//     .then((response) => response.data);
// };

export interface IUploadProductVariables {
  kind: number;
  variety: number;
  output_amount: number;
  deliver_start_date: string;
  deliver_end_date: string;
  description: string;
  differences: number[];
  output_interval: string;
  produce_method: string;
  produce_start_year: number;
  is_representitive: boolean;
}

export const uploadProduct = (variables: IUploadProductVariables) =>
  instance
    .post(`/products/product/`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getUploadURL = () =>
  instance
    .post(`/medias/productimage/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IUploadImageVariables {
  file: FileList;
  uploadURL: string;
}

export const uploadImageToCloud = ({
  file,
  uploadURL,
}: IUploadImageVariables) => {
  const form = new FormData();
  form.append("file", file[0]);
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export interface ICreateImageURLVariables {
  image_file_url: string;
  description: string;
  productId: string;
}

export const createImageURL = ({
  description,
  image_file_url,
  productId,
}: ICreateImageURLVariables) =>
  instance
    .post(
      `/products/product/${productId}/images`,
      { description, image_file_url },
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface ISendOfferVariables {
  offer_detail: string;
  productId: string;
}

export const sendOffer = (variables: ISendOfferVariables) =>
  instance
    .post(`/products/product/${variables.productId}/offers`, variables, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
