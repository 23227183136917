import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FaUserAlt, FaLock, FaEnvelope, FaUserSecret } from "react-icons/fa";
import { signUp } from "../api";
import { useForm } from "react-hook-form";
// import SocialLogin from "./SocialLogin";

interface SignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ISignUpForm {
  name: string;
  email: string;
  username: string;
  password: string;
}

export default function SignUpModal({ isOpen, onClose }: SignUpModalProps) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpForm>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: signUp,
    onSuccess: () => {
      toast({
        title: "welcome!",
        description: "로그인 해주세요",
        status: "success",
      });
      onClose();
      queryClient.refetchQueries({
        queryKey: ["me"],
      });
    },
  });

  const onSubmit = ({ name, email, username, password }: ISignUpForm) => {
    mutation.mutate({ name, email, username, password });
    reset();
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>가입하기</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={"form"} onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaUserSecret />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.name?.message)}
                {...register("name", { required: "이름 입력은 필수 입니다." })}
                variant={"filled"}
                placeholder="이름(회사명)"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaEnvelope />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "이메일 입력은 필수 입니다.",
                  minLength: {
                    value: 5,
                    message: "email must be at least 5 characters",
                  },
                  pattern: {
                    value: /\w+@\w+\.\w+(\.\w+)?$/,
                    message: "please enter a valid email address",
                  },
                })}
                variant={"filled"}
                placeholder="이메일"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaUserAlt />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.username?.message)}
                {...register("username", {
                  required: "아이디 입력은 필수 입니다.",
                })}
                variant={"filled"}
                placeholder="아이디"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaLock />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.password?.message)}
                {...register("password", {
                  required: "비밀번호 입력은 필수 입니다.",
                })}
                variant={"filled"}
                placeholder="비밀번호"
                type="password"
              />
            </InputGroup>
          </VStack>
          <Button
            type="submit"
            isLoading={mutation.isPending}
            mt={4}
            mb={4}
            colorScheme={"green"}
            w="100%"
          >
            등록
          </Button>
          {/* <SocialLogin /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
