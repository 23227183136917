import { Grid } from "@chakra-ui/react";
import ProductSkeleton from "./ProductSkeleton";
import { getProducts } from "../api";
import { IProduct } from "../types";
import { useQuery } from "@tanstack/react-query";
import Product from "./Product";

export default function ProductsList() {
  const { isLoading, data } = useQuery<IProduct[]>({
    queryKey: ["products"],
    queryFn: getProducts,
  });
  return (
    <Grid
      mt={10}
      px={{
        base: 10,
        lg: 40,
      }}
      columnGap={4}
      rowGap={8}
      templateColumns={{
        sm: "1fr",
        md: "1fr 1fr",
        lg: "repeat(4, 1fr)",
        xl: "repeat(5, 1fr)",
        "2xl": "repeat(6, 1fr)",
      }}
    >
      {isLoading ? (
        <>
          <ProductSkeleton />
          <ProductSkeleton />
          <ProductSkeleton />
          <ProductSkeleton />
        </>
      ) : null}
      {data?.map((product) => (
        <Product
          key={product.id}
          id={product.id}
          isOwner={product.is_owner}
          imageUrl={product.product_images[0]?.image_file_url}
          kind={product.variety.kind.name}
          varietyName={product.variety.variety_name}
          location={
            product.producer.location.depth_1 +
            " " +
            product.producer.location.depth_2
          }
          amount={product.output_amount}
          deliveryStart={product.deliver_start_date}
          deliveryEnd={product.deliver_end_date}
        />
      ))}
    </Grid>
  );
}
