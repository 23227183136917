import {
  Box,
  Flex,
  Grid,
  HStack,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { IVariety } from "../types";
import { getVarieties } from "../api";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import ReactMarkdown from "react-markdown";

export default function VarietyList() {
  const { data } = useQuery<IVariety[]>({
    queryKey: ["varieties"],
    queryFn: getVarieties,
  });
  return (
    <Grid
      mt={10}
      px={{ base: 10, sm: 20, lg: 40 }}
      columnGap={10}
      rowGap={8}
      templateColumns={{
        sm: "1fr",
        md: "1fr, 1fr",
        lg: "repeat(3, 1fr)",
      }}
    >
      {data?.map((variety) => (
        <Box w={"100%"} minH={"300"}>
          <Stack>
            <Text
              color={"green.500"}
              textTransform={"uppercase"}
              fontWeight={800}
              fontSize={"sm"}
              letterSpacing={1.1}
            >
              {variety.kind.name}
            </Text>
            <HStack justifyContent={"space-between"}>
              <Heading
                // eslint-disable-next-line react-hooks/rules-of-hooks
                color={"black"}
                fontSize={"2xl"}
                fontFamily={"body"}
              >
                {variety.variety_name}
              </Heading>
              <Link to={variety.seed_url} target="_blank">
                <Flex
                  w={10}
                  h={10}
                  align={"center"}
                  justify={"center"}
                  color={"white"}
                  rounded={"full"}
                  bg={"gray.100"}
                  mb={1}
                >
                  <FaExternalLinkAlt color="black" size={13} />
                </Flex>
              </Link>
            </HStack>
            <ReactMarkdown>{variety.description}</ReactMarkdown>
            {/* <Text color={"gray.500"}>{variety.description}</Text> */}
          </Stack>
        </Box>
      ))}
    </Grid>
  );
}
