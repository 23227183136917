import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <VStack bg={"gray.100"} justifyContent={"center"} minH={"100vh"}>
      <Heading>페이지를 찾을 수 없습니다.</Heading>
      <Text>유효하지 않은 페이지입니다.</Text>
      <Link to="/">
        <Button colorScheme="green" variant={"link"}>
          Home 으로 돌아가기 &rarr;
        </Button>
      </Link>
    </VStack>
  );
}
