import {
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function Introduction() {
  return (
    <>
      <Container>
        <VStack
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
          mt={10}
        >
          <Stack
            direction={"row"}
            spacing={10}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
          >
            <a href="/product">
              <Button
                colorScheme={"green"}
                bg={"green.400"}
                rounded={"full"}
                px={6}
                _hover={{
                  bg: "green.400",
                }}
              >
                등록 상품 보기
              </Button>
            </a>
            <a href="/variety">
              <Button
                colorScheme={"green"}
                bg={"gray.300"}
                rounded={"full"}
                px={6}
                _hover={{
                  bg: "gray.300",
                }}
              >
                신품종 목록
              </Button>
            </a>
          </Stack>
          <Image
            src="https://imagedelivery.net/2ctSj11pyjS7wsD5zUJWYw/36774088-9e79-493b-483c-a9a79b6c0200/public"
            w={"100%"}
          ></Image>
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
          >
            새로운 품종을 찾아보세요 <br />
            <Text as={"span"} color={"green.400"}>
              FRESH LINK 가 연결합니다
            </Text>
          </Heading>
          <Text color={"gray.500"}>
            신품종을 육성 중인데 판로가 고민이신가요? 차별화된 품종으로 브랜드를
            만들고 싶으신가요? 신품종 생산자와 새로운 농산품이 필요한 유통업체를
            연결하여 부가가치 높은 농산물 유통에 도움을 드립니다.
          </Text>
        </VStack>
      </Container>
    </>
  );
}
