import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../api";
import { IProductDetail } from "../types";
import {
  AbsoluteCenter,
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FcCheckmark } from "react-icons/fc";
import { FaAngleRight, FaStar } from "react-icons/fa";
import ReactMarkdown from "react-markdown";

export default function ProductDetail() {
  const { productId } = useParams();
  const { isLoading, data } = useQuery<IProductDetail>({
    queryKey: ["product", productId],
    queryFn: getProduct,
  });
  const num_amount = Number(data?.output_amount).toLocaleString();
  const navigate = useNavigate();
  const onOfferClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/product/${productId}/offer`);
  };
  return (
    <Box
      mt={10}
      px={{
        base: 10,
        lg: 40,
      }}
    >
      {/* Product Images */}
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(4, 1fr)",
        }}
        templateRows={{
          sm: "repeat(4, 1fr)",
          md: "1fr 1fr",
        }}
        height={"60vh"}
        gap={3}
        rounded={"md"}
        overflow={"hidden"}
      >
        {[0, 1].map((productImage, index) => (
          <GridItem colSpan={2} rowSpan={2} overflow={"hidden"} key={index}>
            <Skeleton isLoaded={!isLoading} h="100%" w={"100%"}>
              {data?.product_images && data.product_images.length > 1 ? (
                <Image
                  w={"100%"}
                  h={"100%"}
                  objectFit={"cover"}
                  src={data?.product_images[index]?.image_file_url}
                />
              ) : (
                <Image
                  w={"100%"}
                  h={"100%"}
                  objectFit={"cover"}
                  src="https://imagedelivery.net/2ctSj11pyjS7wsD5zUJWYw/2e3f0fc5-5228-45da-10de-9b966a6e2500/public"
                />
              )}
            </Skeleton>
          </GridItem>
        ))}
      </Grid>
      <Skeleton
        height={"43px"}
        width={"100%"}
        isLoaded={!isLoading}
        mt={1}
        mb={20}
      >
        <HStack spacing={10}>
          <Heading size={{ sm: "md", md: "lg" }}>
            {data?.variety.kind.name} · {data?.variety.variety_name}
          </Heading>
          <HStack gap={5} alignItems={"flex-start"} py={10}>
            {data?.differences.map((difference) => (
              <HStack key={difference.id}>
                <FaStar color="red" />
                <Text fontWeight={700}>{difference.keyword}</Text>
              </HStack>
            ))}
          </HStack>
        </HStack>
      </Skeleton>

      {/* Product Description */}
      <Grid templateColumns={{ sm: "1fr", lg: "2fr 1fr" }} gap={10}>
        <GridItem w="100%" minH="10">
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter bg="white" px="4">
              <Text
                fontSize={{
                  base: "16px",
                  lg: "18px",
                }}
                fontWeight={"600"}
                color={"green.700"}
              >
                출하 정보
              </Text>
            </AbsoluteCenter>
          </Box>
          <Box px={10}>
            <VStack alignItems={"flex-start"}>
              <HStack>
                <FaAngleRight color="red" />
                <Text fontWeight={600}>예상출하시기</Text>
                <Text>
                  {data?.deliver_start_date} ~ {data?.deliver_end_date}
                </Text>
              </HStack>
              <HStack>
                <FaAngleRight color="red" />
                <Text fontWeight={600}>주기/물량</Text>
                <Text>
                  {data?.output_interval} / 총 {num_amount} kg
                </Text>
              </HStack>
            </VStack>
          </Box>
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter bg="white" px="4">
              <Text
                fontSize={{
                  base: "16px",
                  lg: "18px",
                }}
                fontWeight={"600"}
                color={"green.700"}
              >
                생산 정보
              </Text>
            </AbsoluteCenter>
          </Box>

          <Box px={10}>
            <VStack alignItems={"flex-start"}>
              <HStack>
                <FaAngleRight color="red" />
                <Text fontWeight={600}>생산지</Text>
                <Text>
                  {data?.producer.location.depth_1}{" "}
                  {data?.producer.location.depth_2}
                </Text>
              </HStack>

              <HStack>
                <FaAngleRight color="red" />
                <Text fontWeight={600}>생산환경</Text>
                <Text>
                  {data?.produce_method === "facility" ? "시설" : "노지"}
                </Text>
              </HStack>
              <HStack>
                <FaAngleRight color="red" />
                <Text fontWeight={600}>생산 시작 연도</Text>
                <Text>{data?.produce_start_year}년</Text>
              </HStack>
            </VStack>
          </Box>

          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter bg="white" px="4">
              <Text
                fontSize={{
                  base: "16px",
                  lg: "18px",
                }}
                fontWeight={"600"}
                color={"green.700"}
              >
                상세 설명
              </Text>
            </AbsoluteCenter>
          </Box>
          <Box w={"100%"} p={10}>
            <ReactMarkdown>{data?.description}</ReactMarkdown>
          </Box>
        </GridItem>
        <GridItem w="100%" minH="10" bg="gray.100" borderRadius={"1"}>
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter bg="green.500" px="4" color={"white"}>
              <Text fontWeight={"600"}>생산자 정보</Text>
            </AbsoluteCenter>
          </Box>
          <HStack px={10} spacing={5} justifyContent={"space-evenly"} mb={5}>
            <VStack>
              <Box mb={3}>
                <Avatar size={"md"} name={data?.producer.user}></Avatar>
              </Box>
              <HStack>
                <FcCheckmark color="red" />
                <Text fontWeight={600}>
                  생산종: {data?.producer.get_variety_status_display}
                </Text>
              </HStack>
              <HStack>
                <FcCheckmark color="red" />
                <Text fontWeight={600}>주출하경로:</Text>
                <Text>{data?.producer.get_distribution_path_display}</Text>
              </HStack>
            </VStack>
          </HStack>
          <Box position="relative" padding="10">
            <Divider />
            <AbsoluteCenter bg="green.500" px="4" color={"white"}>
              <Text fontWeight={"600"}>인증 정보</Text>
            </AbsoluteCenter>
          </Box>
          {/* <Box px={10}>
            <Text>GAP</Text>
          </Box> */}
        </GridItem>
      </Grid>
      <Center m={10}>
        <Button
          variant={"outline"}
          colorScheme={"green"}
          borderRadius={5}
          onClick={onOfferClick}
        >
          제안 보내기
        </Button>
      </Center>
    </Box>
  );
}
