import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ProducerOnlyPage from "../components/ProducerOnlyPage";
import ProtectedPage from "../components/ProtectedPage";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IUploadProductVariables,
  getDifferences,
  getKind,
  uploadProduct,
} from "../api";
import { IDifference, IKind, IProductDetail, IVariety } from "../types";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function UploadProduct() {
  const { register, handleSubmit } = useForm<IUploadProductVariables>();
  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: uploadProduct,
    onSuccess: (data: IProductDetail) => {
      toast({
        status: "success",
        title: "정상적으로 등록되었습니다.",
        position: "bottom-right",
      });
      navigate(`/product/${data.id}`);
    },
    onError: (e) => {
      console.error("Error during product upload:", e);
      toast({
        status: "error",
        title: "등록에 실패했습니다.",
        description: e.message || "알 수 없는 오류가 발생했습니다.",
        position: "bottom-right",
      });
    },
  });
  const { data: differences } = useQuery<IDifference[]>({
    queryKey: ["differences"],
    queryFn: getDifferences,
  });

  const { data: kind } = useQuery<IKind[]>({
    queryKey: ["kind"],
    queryFn: getKind,
  });

  const [kindSelected, setKindSelected] = useState<number | undefined>(
    undefined
  );
  const [varietyOptions, setVarietyOptions] = useState<IVariety[]>([]);
  const [varietySelected, setVarietySelected] = useState<number | undefined>(
    undefined
  );

  const getVarietyOptions = async (kindId: number) => {
    const instance = axios.create({
      baseURL:
        process.env.NODE_ENV === "development"
          ? "http://127.0.0.1:8000/api/v1/"
          : "https://backend.freshlink.kr/api/v1/",
      withCredentials: true,
    });

    try {
      const response = await instance.get<IVariety[]>(
        `products/kind/${kindId}/variety/`
      );
      setVarietyOptions(response.data);
    } catch (e) {
      console.error("Error fetching variety options:", e);
    }
  };

  useEffect(() => {
    if (kindSelected !== undefined) {
      getVarietyOptions(kindSelected);
    }
  }, [kindSelected]);

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedKindId = parseInt(e.target.value, 10);
    setKindSelected(selectedKindId);
  };
  const onSubmit = (data: IUploadProductVariables) => {
    console.log("Submitting form with data:", data);
    mutation.mutate(data);
  };
  return (
    <ProtectedPage>
      <ProducerOnlyPage>
        <Box pb={40} mt={10} px={{ base: 10, lg: 40 }}>
          <Container>
            <Heading textAlign={"center"}>생산품 등록</Heading>
            <VStack
              spacing={10}
              as={"form"}
              mt={5}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl>
                <FormLabel>품목</FormLabel>
                <Select
                  {...register("kind", { required: true })}
                  placeholder="품목을 선택하세요."
                  value={kindSelected}
                  onChange={handleSelect}
                >
                  {kind?.map((k) => (
                    <option value={k.id} key={k.id}>
                      {k.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>품종</FormLabel>
                <Select
                  {...register("variety", { required: true })}
                  placeholder="품종을 선택하세요."
                  value={varietySelected}
                  onChange={(e) => setVarietySelected(parseInt(e.target.value))}
                  isDisabled={!kindSelected}
                >
                  {varietyOptions?.map((v) => (
                    <option value={v.id} key={v.id}>
                      {v.variety_name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>차별점</FormLabel>
                <FormHelperText>기존 품종 대비 차별점</FormHelperText>
                <Grid templateColumns={"1fr 1fr"} gap={2} mt={5}>
                  {differences?.map((difference) => (
                    <Box key={difference.id}>
                      <Checkbox
                        {...register("differences", { required: true })}
                        value={difference.id}
                      >
                        {difference.keyword}
                      </Checkbox>
                    </Box>
                  ))}
                </Grid>
              </FormControl>

              <FormControl>
                <FormLabel>상세 설명</FormLabel>

                <Textarea
                  {...register("description", { required: true })}
                  placeholder="생산품종에 대한 상세 설명을 적어주세요."
                />
              </FormControl>
              <FormControl>
                <FormLabel>생산 환경</FormLabel>

                <Select
                  {...register("produce_method", { required: true })}
                  placeholder="시설/노지 중 선택"
                >
                  <option value="facility">시설</option>
                  <option value="field">노지</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>생산시작연도</FormLabel>
                <InputGroup>
                  <Input
                    {...register("produce_start_year", { required: true })}
                    type="number"
                    min={1950}
                  ></Input>
                  <InputRightAddon children={"년"} />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel>출하 주기</FormLabel>
                <Input
                  {...register("output_interval", { required: true })}
                  type="text"
                />
                <FormHelperText>예시:주 2회</FormHelperText>
              </FormControl>
              <HStack w={"100%"}>
                <FormControl>
                  <FormLabel>출하 시작 시기 (예정)</FormLabel>
                  <Input
                    {...register("deliver_start_date", { required: true })}
                    type="text"
                    placeholder="예시: 6월 중순"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>출하 종료 시기 (예상)</FormLabel>
                  <Input
                    {...register("deliver_end_date", { required: true })}
                    type="text"
                    placeholder="예시: 9월 초순"
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel>물량</FormLabel>
                <InputGroup>
                  <Input
                    {...register("output_amount", { required: true })}
                    type="number"
                    min={10}
                  ></Input>
                  <InputRightAddon children={"kg"} />
                </InputGroup>
              </FormControl>
              <FormControl>
                <Checkbox {...register("is_representitive")}>
                  대표 생산 품종 여부
                </Checkbox>
              </FormControl>
              {mutation.isError ? (
                <Text color={"red.500"}>입력값이 올바르지 않습니다.</Text>
              ) : null}
              <Button
                type="submit"
                isLoading={mutation.isPending}
                colorScheme="green"
                size={"lg"}
                w={"100%"}
              >
                등록하기
              </Button>
            </VStack>
          </Container>
        </Box>
      </ProducerOnlyPage>
    </ProtectedPage>
  );
}

//produce_start_year
//deliver_start_date
//deliver_end_date
//is_representitive
