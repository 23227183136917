import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";

function ProductSkeleton() {
  return (
    <Box>
      <Skeleton height={"200"} rounded={"xl"} mb={6}></Skeleton>
      <SkeletonText w={"50%"} noOfLines={1} mb={4} />
      <SkeletonText w={"50%"} noOfLines={1} mb={9} />
      <SkeletonText w={"50%"} noOfLines={1} mb={2} />
    </Box>
  );
}

export default ProductSkeleton;
