import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { FcShipped, FcSearch } from "react-icons/fc";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <VStack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </VStack>
  );
};

export default function Features() {
  return (
    <>
      <Container>
        <Box mb={5} textAlign={"center"}>
          <Heading fontSize={"3xl"}>이런 문제를 해결합니다.</Heading>
        </Box>
        <Box p={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Feature
              icon={<Icon as={FcShipped} w={10} h={10} />}
              title={"생산자의 신품종 판로 개척"}
              text={
                "알려지지 않아 소비자는 찾지 않고 도매시장에서는 제값을 못받아 고민이 되셨다면, 프레시링크에서 상품화에 도움을 줄 유통업체를 만나보세요."
              }
            />
            <Feature
              icon={<Icon as={FcSearch} w={10} h={10} />}
              title={"유통업체의 신상 농산물 소싱"}
              text={
                "신비복숭아, 샤인머스캣 같은 스타 농산품을 개발하고 싶은데 누가 어디에서 생산하는지 찾을 수가 없었다면, 프레시링크에서 농가를 찾아 소싱해보세요."
              }
            />
          </SimpleGrid>
        </Box>
      </Container>
    </>
  );
}
