import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../components/ProtectedPage";

export default function CreateProducer() {
  const clickButton = () => {
    alert("점검 중입니다.");
  };
  return (
    <ProtectedPage>
      <Box pb={40} mt={10} px={{ base: 10, lg: 40 }}>
        <Container>
          <Heading textAlign={"center"}>생산자 프로필 등록</Heading>
          <VStack spacing={10} mt={5}>
            <FormControl>
              <FormLabel>생산지역</FormLabel>
              <Input type="text" />
            </FormControl>

            <FormControl>
              <FormLabel>육성 품종 구성</FormLabel>
              <Select placeholder="선택필수">
                <option value="various">다품종</option>
                <option value="one">단일품종</option>
              </Select>
              <FormHelperText>
                생산 품종이 다양한 경우 "다품종" 선택
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>주요 출하 경로</FormLabel>
              <Select placeholder="선택필수">
                <option value="wholesalesmarket">도매시장</option>
                <option value="localdistributor">산지유통인</option>
                <option value="apc">유통센터</option>
                <option value="supermarket">대형소매업체(마트)</option>
                <option value="direct">직거래</option>
              </Select>
            </FormControl>
            <Button
              type="submit"
              colorScheme="green"
              size={"lg"}
              w={"100%"}
              onClick={clickButton}
            >
              등록하기
            </Button>
          </VStack>
        </Container>
      </Box>
    </ProtectedPage>
  );
}
