import {
  Box,
  Button,
  Grid,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

interface ProductProps {
  id: number;
  imageUrl: string;
  kind: string;
  varietyName: string;
  location: string;
  amount: number;
  deliveryStart: string;
  deliveryEnd: string;
  isOwner: boolean;
}

export default function Product({
  id,
  imageUrl,
  kind,
  varietyName,
  location,
  amount,
  deliveryStart,
  deliveryEnd,
  isOwner,
}: ProductProps) {
  const navigate = useNavigate();
  const onCameraClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/product/${id}/images`);
  };
  return (
    <Link to={`/product/${id}`}>
      <VStack alignItems={"center"}>
        <Box position={"relative"} overflow={"hidden"} rounded={"xl"} mb={2}>
          {imageUrl ? (
            <Image
              minH="200"
              maxH={{
                sm: 250,
                md: 250,
                lg: 200,
              }}
              src={imageUrl}
              objectFit={"cover"}
            />
          ) : (
            <Image
              minH="200"
              maxH={{
                sm: 250,
                md: 250,
                lg: 200,
              }}
              src="https://imagedelivery.net/2ctSj11pyjS7wsD5zUJWYw/2e3f0fc5-5228-45da-10de-9b966a6e2500/public"
              objectFit={"cover"}
            />
          )}
          {isOwner ? (
            <Button
              variant={"ghost"}
              position="absolute"
              top={0}
              right={0}
              color={"white"}
              onClick={onCameraClick}
            >
              <FaCamera size={"20"} />
            </Button>
          ) : null}
        </Box>
        <Box>
          <Grid templateColumns={"5fr 1fr"} gap={2}>
            <Text as="b" noOfLines={1} fontSize={"md"}>
              {kind} &bull; {varietyName}
            </Text>
            <HStack spacing={"1"} alignItems="center">
              <Text as="b" fontSize={"sm"}>
                {Number(amount).toLocaleString()}kg
              </Text>
            </HStack>
          </Grid>
          <Text fontSize={"sm"} color={"gray.600"}>
            {location}
          </Text>
        </Box>
      </VStack>
    </Link>
  );
}
