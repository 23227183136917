import { Box } from "@chakra-ui/react";

import Features from "../components/Features";
import Introduction from "../components/Introduction";

export default function Home() {
  return (
    <>
      <Box>
        <Introduction />
      </Box>
      <Box mb={10}>
        <Features />
      </Box>
    </>
  );
}
