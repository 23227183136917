import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ProducerOnlyPage from "../components/ProducerOnlyPage";
import ProtectedPage from "../components/ProtectedPage";
import { useMutation } from "@tanstack/react-query";
import { createImageURL, getUploadURL, uploadImageToCloud } from "../api";

interface IUploadImageForm {
  file: FileList;
}

interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}

export default function UploadImeges() {
  const { register, handleSubmit, watch, reset } = useForm<IUploadImageForm>();
  const { productId } = useParams();
  const toast = useToast();
  const createImageURLMutation = useMutation({
    mutationFn: createImageURL,
    onSuccess: () => {
      toast({
        status: "success",
        title: "사진이 등록되었습니다.",
        description: "최대 2개까지 등록할 수 있습니다.",
        isClosable: true,
      });
      reset();
    },
  });
  const uploadImageMutation = useMutation({
    mutationFn: uploadImageToCloud,
    onSuccess: ({ result }: any) => {
      if (productId) {
        createImageURLMutation.mutate({
          description: "images!",
          image_file_url: `https://imagedelivery.net/2ctSj11pyjS7wsD5zUJWYw/${result.id}/public`,
          productId,
        });
      }
    },
  });
  const uploadURLMutation = useMutation({
    mutationFn: getUploadURL,
    onSuccess: (data: IUploadURLResponse) => {
      uploadImageMutation.mutate({
        uploadURL: data.uploadURL,
        file: watch("file"),
      });
    },
  });

  const onSubmit = (data: any) => {
    uploadURLMutation.mutate();
  };
  return (
    <ProtectedPage>
      <ProducerOnlyPage>
        <Box
          pb={40}
          mt={10}
          px={{
            base: 10,
            lg: 40,
          }}
        >
          <Container>
            <Heading textAlign={"center"}>사진 첨부</Heading>
            <VStack
              as={"form"}
              onSubmit={handleSubmit(onSubmit)}
              spacing={5}
              mt={10}
            >
              <FormControl>
                <Input {...register("file")} type="file" accept="image/*" />
              </FormControl>
              <Button
                isLoading={
                  createImageURLMutation.isPending ||
                  uploadImageMutation.isPending ||
                  uploadURLMutation.isPending
                }
                type="submit"
                w="100%"
                color={"green"}
              >
                Upload images
              </Button>
            </VStack>
          </Container>
        </Box>
      </ProducerOnlyPage>
    </ProtectedPage>
  );
}
