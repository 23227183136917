import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import ProtectedPage from "../components/ProtectedPage";

export default function CreateDistributor() {
  const clickButton = () => {
    alert("점검 중입니다.");
  };
  return (
    <ProtectedPage>
      <Box pb={40} mt={10} px={{ base: 10, lg: 40 }}>
        <Container>
          <Heading textAlign={"center"}>유통업체 프로필 등록</Heading>
          <VStack spacing={10} mt={5}>
            <FormControl>
              <FormLabel>관심 품목</FormLabel>
              <Grid templateColumns={"1fr 1fr 1fr"} gap={2} mt={5}>
                <Box>
                  <Checkbox>포도</Checkbox>
                </Box>
                <Box>
                  <Checkbox>복숭아</Checkbox>
                </Box>
                <Box>
                  <Checkbox>딸기</Checkbox>
                </Box>
              </Grid>
            </FormControl>

            <FormControl>
              <FormLabel>도소매구분</FormLabel>
              <Select placeholder="선택필수">
                <option value="wholesaler">도매</option>
                <option value="retailer">소매</option>
                <option value="both">도소매</option>
              </Select>
            </FormControl>
            <FormControl>
              <Checkbox>조합 여부</Checkbox>
            </FormControl>
            <FormControl>
              <FormLabel>판매/유통 채널</FormLabel>
              <Select placeholder="선택필수">
                <option value="online">온라인 플랫폼</option>
                <option value="offline">오프라인 점포</option>
                <option value="both">온오프라인 모두</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>소개글</FormLabel>
              <Textarea
                placeholder="유통 규모 등 상세 소개글을 작성해주세요."
                h={"200"}
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="green"
              size={"lg"}
              w={"100%"}
              onClick={clickButton}
            >
              등록하기
            </Button>
          </VStack>
        </Container>
      </Box>
    </ProtectedPage>
  );
}
