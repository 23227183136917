import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ProtectedPage from "../components/ProtectedPage";
import { useForm } from "react-hook-form";
import { ISendOfferVariables, sendOffer } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

export default function SendOffer() {
  const { register, handleSubmit } = useForm<ISendOfferVariables>();
  const { productId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: sendOffer,
    onSuccess: () => {
      toast({
        status: "success",
        title: "정상적으로 전송되었습니다.",
        position: "bottom-right",
      });
      navigate(`/product/${productId}`);
    },
  });
  const onSubmit = (data: ISendOfferVariables) => {
    if (productId) {
      data.productId = productId;
      mutation.mutate(data);
    }
  };
  return (
    <ProtectedPage>
      <Box pb={40} mt={10} px={{ base: 10, lg: 40 }}>
        <Container>
          <Heading textAlign={"center"}>제안 보내기</Heading>
          <VStack
            as={"form"}
            spacing={5}
            mt={10}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl>
              <Textarea
                placeholder="제안하는 내용에 대해 상세하게 작성해주세요."
                h={"300"}
                {...register("offer_detail", { required: true })}
              ></Textarea>
            </FormControl>
            <Button
              type="submit"
              isLoading={mutation.isPending}
              colorScheme="green"
              size={"lg"}
              w={"100%"}
            >
              전달하기
            </Button>
          </VStack>
        </Container>
      </Box>
    </ProtectedPage>
  );
}
